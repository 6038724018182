const home = {
  copy:{
    intro:    <>Is your house workplace or school making you sick?<br/>Are your children playing in a contaminated backyard?</>,
    title:    'Environmental Engineer',
    copy:     'Call Us Today:',
    phone:    '0458 876 290',
    phoneUrl: 'tel:+61458876290'
  }
};

const Home = () =>  (
    <section className='container-fluid p-0 min-vh-100 bg-image-home'>
    <section className='container-fluid p-0 min-vh-100 d-flex justify-content-center align-items-center bg-gradient-danube'>
    <div className='row px-4 g-0 justify-content-center align-items-center '>
      <div className='col-sm text-center animate-bottom'>
        <div className='col'>{home.copy.intro}</div>  
        <h1 className='py-4'>{home.copy.title}</h1>
        <div className='col'>{home.copy.copy}</div>  
        <div className='col'><a href={home.copy.phoneUrl} className={'text-white text-decoration-none'} target={'_blank'} rel={'noreferrer'}>{home.copy.phone}</a></div>  
        </div>
      </div>
    </section>
    </section>
)

export default Home;
