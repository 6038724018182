const engineers = {
  id:'engineers',
  copy:{
    title: <>Professional Service from Qualified Engineers<br/><br/></>,
    list: ['From start to finish you get professional service from qualified engineers', <>Don't put yourself at risk with unqualified contractors</>, 'Cheap competitive rates', 'Quick turn arounds to keep your project on track', 'Fully insured with Professional Indemnity and Public Liability Insurance', 'We comply with all relevant Australian Standards']
  }
};

const listItems = engineers.copy.list.map((list) => <li key={list}>{list}</li>);
  
const Engineers = () => (
  <section className='container-fluid p-0 pt-5 bg-white' id={engineers.id}>
      <div className='container justify-content-md-center py-5'>
      <div className='row px-3 g-0 flex-wrap-reverse animate-bottom'>
        <div className='p-0 animate-bottom'>
          <h2>{engineers.copy.title}</h2>
          <ul>{listItems}</ul>  
        </div>
      </div>
    </div>
  </section >
)

export default Engineers;