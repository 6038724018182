import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha'

function Mailer () {

    const form = useRef()

    const [show, setShow] = useState(undefined);

    const onChange = () => {
      document.getElementById('send-button').disabled = false;
    } 

    const sendEmail = (e) => {
      e.preventDefault();
        emailjs.sendForm
        (
            'service_bisank1', 
            'template_dsays3b', 
            e.target,
            'W19rw2dUHNtpDrqzL'
        )
        .then(()=>
            {
              setShow({type: 'success'})
              e.target.reset()
            })
        .catch(error=>
          setShow({type:  'error', error})
        )
    }
    return (
      <div className='container p-0'>
      <form ref={form} onSubmit={sendEmail}>
        <div className='row'>
          <div className='col-md  pb-2'>
            <label className='form-label'>Name</label><br/>
            <input type='text' name='user_name' placeholder='your name' className='form-control' required/>
          </div>
          <div className='col-md  pb-2'>
            <label className='form-label'>Email</label><br/>
            <input type='email' name='user_email' placeholder='your email' className='form-control' required/>
          </div>
        </div>
        <div className='row pb-2'>
        <div className='col'>
        <label className='form-label'>Message</label><br/>
        <textarea type='textarea' name='message' placeholder='your message' className='form-control' required/><br/>
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITEKEY} onChange={onChange} className='mb-3'/>
        <br/>

        <input id='send-button' type='submit' value='Send' variant='primary' className='btn btn-primary bg-sunglow border-0' disabled/>

        </div>
        </div>
      </form>
      <>
        {show?.type === 'success' && <div className='mt-2'>Your message has been sent successfully</div>}
        {show?.type === 'error' && <div className='mt-2'>Message sending failed. Please try again.</div>}
      </>
      </div>
    );
  };


export default Mailer;