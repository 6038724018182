import Mailer from './mailer.js';
import Map from './map.js';

const contact = {
  id:'contact',
  copy:{
    title:'Contact Us'
  },
  phone:{
    intro: 'Mobile: ',
    copy: '0458 876 290',
    url: 'tel:+61458876290'
  },
  email:{
    intro: 'Email: ',
    copy:'info@structuralassessments.com.au',
    url:'mailto:info@structuralassessments.com.au'
  },
  address:{
    intro: 'Our Address:',
    copy:'2/27 Plane St, Thomastown VIC 3074'
  }
};

const Contact = () =>  (
    <section className='container-fluid p-0 pt-5 bg-mantis' id={contact.id}>
      <div className='container justify-content-md-center py-5'>
      <div className='row px-4 animate-bottom'>
          <h2>{contact.copy.title}</h2>
      </div>
      <div className='row px-4 gx-lg-5 animate-bottom'>

        <div className='col-lg-6 py-2'>
        <Mailer />
        </div>
        <div className='col-lg-6 py-2'>
          {contact.phone.intro}<br/><a href={contact.phone.url} className='text-white'>{contact.phone.copy}</a>
          <br/><br/>
          {contact.email.intro}<br/><a href={contact.email.url} className='text-white'>{contact.email.copy}</a>
          <br/><br/>
          {contact.address.intro}<br/>{contact.address.copy}
          <br/><br/>
          <Map />
        </div>
        </div>
      </div>
    </section>
)

export default Contact;
