const about = {
  id:'aboutus',
  copy:{
    title: 'Environmental Engineer',
    copy01: <><br/>Is your house workplace or school making you sick?<br/>Are your children playing in a contaminated backyard?<br/><br/>Environmental Engineer<br/><br/></>,
    list: ['For Health and Safety', 'For Waste removal', 'For environmental Surveys'],
    copy02: <><br/>Soil contamination testing for heavy metals, asbestos, pesticides, petrochemicals, PFAS and many more toxins.<br/><br/> All testing and handling is carried out to the latest Australian Standards and by NATA accredited laboratories.<br/><br/> Mould Testing<br/><br/> We test for toxic mould in your house, workplace or school.<br/><br/> We test for wood eating mould.</>
  }
};

const listItems = about.copy.list.map((list) => <li key={list}>{list}</li>);

const About = () =>  (
    <section className='container-fluid p-0 pt-5 bg-mantis' id={about.id}>
      <div className='container justify-content-md-center py-5'>
      <div className='row px-3 g-0 animate-bottom'>
        <div className='col-lg-6'>
          <h2>{about.copy.title}</h2>
          {about.copy.copy01}
          <ul>{listItems}</ul> 
          {about.copy.copy02}
        </div>
        </div>
      </div>
    </section>
)

export default About;