const map = {
    src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3157.863133028118!2d145.02277669999998!3d-37.6759237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64f1726b7c491%3A0xc7968aaa96710907!2s2%2F27%20Plane%20St%2C%20Thomastown%20VIC%203074!5e0!3m2!1sen!2sau!4v1707131436655!5m2!1sen!2sau',
    className:'mw-90'
}

const Map =()=> (
      <iframe src={map.src} width={600} height={450} allowFullScreen={''} loading={'lazy'} referrerPolicy={'no-referrer-when-downgrade'} aria-hidden={false} tabIndex={0} title={'map'} className={map.className}></iframe>
)

export default Map
