const services = {
  id:'services',
  copy:{
    title: <>Services</>,
    copy01:'Soil contamination testing for:',
    list: ['Heavy metals', 'Asbestos', 'Pesticides', 'Petrochemichals', 'PFAS'],
    copy02:<>and many more toxins.<br/><br/>Soil testing and reporting for site classification and foundation design<br/><br/>Diagnosing the causes of cracked and damaged houses and structures<br/><br/>Rock bearing capacity and design strength<br/><br/>Meeting council requirements for Erosion Management Overlays (EMO)<br/><br/>Percolation/Permeability Testing for Stormwater Disposal<br/><br/>Marine Geotech investigations<br/><br/>Level 1 site supervision and design verification<br/><br/>Land Capability Assessments for septic systems<br/><br/>Footing design inspection<br/><br/>Standpipe installation<br/><br/>Groundwater monitoring</>,
  }
};

const listItems = services.copy.list.map((list) => <li key={list}>{list}</li>);
  
const Services = () => (
  <section className='container-fluid p-0 min-vh-100 bg-image-01' id={services.id}>
    <section className='container-fluid p-0  pt-5 min-vh-100 bg-gradient-sunglow'>
      <div className='container justify-content-md-center py-5'>
        <div className='row px-3 g-0 animate-bottom'>
          <div className='col-lg-6'>
            <h2>{services.copy.title}</h2><br/>
            {services.copy.copy01}<br/><br/>
            <ul>{listItems}</ul><br/>
            {services.copy.copy02}
          </div>
        </div>
      </div >
    </section>
  </section >
)

export default Services;